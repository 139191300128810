<template>
  <b-row class="justify-content-center">
    <b-col md="8">
      <b-card>
        <b-form v-on:submit.prevent>
          <b-row>
            <b-col>
              <b-form-group label="Pelanggan" label-for="customer">
                <v-select
                  id="customer"
                  v-model="form.selectedCustomer"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="form.optionsCustomer"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedCustomer"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <!-- Selected: <strong>{{ form.selectedOrganization }}</strong> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Jenis Sewa" label-for="type">
                <v-select
                  id="type"
                  v-model="form.selectedType"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="form.optionsType"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedType"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <!-- Selected: <strong>{{ form.selectedOrganization }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Periode Sewa" label-for="period">
                <v-select
                  id="period"
                  v-model="form.selectedPeriod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="form.optionsPeriod"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedPeriod"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <!-- Selected: <strong>{{ form.selectedOrganization }}</strong> -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Tanggal Mulai" label-for="start-date">
                <flat-pickr
                  v-model="form.startDate"
                  class="form-control"
                  :config="{
                    defaultDate: 'today',
                    dateFormat: 'd-m-Y',
                    minDate: 'today',
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Tanggal Berakhir" label-for="end-date">
                <flat-pickr
                  v-model="form.endDate"
                  class="form-control"
                  :config="{
                    dateFormat: 'd-m-Y',
                    minDate: form.startDate,
                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Nilai Kontrak" label-for="price">
                <cleave
                  id="price"
                  placeholder="Nilai Kontrak"
                  v-model="form.price"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Alamat Pengiriman"
                label-for="delivery-address"
              >
                <b-form-textarea
                  id="delivery-address"
                  placeholder="Alamat Pengiriman"
                  v-model="form.deliveryAddress"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- submit and reset -->
            <b-col md="6" class="mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="validation()"
              >
                Simpan
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BImg,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    ToastificationContent,
    vSelect,
    Cleave,
    BImg,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        selectedCustomer: null,
        optionsCustomer: [],
        selectedType: null,
        optionsType: [
          {
            value: 1,
            text: "Lepas Kunci",
          },
          {
            value: 2,
            text: "Dengan Driver",
          },
        ],
        selectedPeriod: null,
        optionsPeriod: [
          {
            value: 1,
            text: "Harian",
          },
          {
            value: 2,
            text: "Bulanan",
          },
          {
            value: 3,
            text: "Tahunan",
          },
        ],
        image: "",
        name: "",
        code: "",
        startDate: null,
        endDate: null,
        category_name: "",
        mileage: 0,
        price: null,
        deliveryAddress: "",
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      errors: "",
      errMessage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  watch: {
    "form.selectedUnit": function (data) {
      if (data.value != null) {
        this.trigger(data.value);
      }
    },
  },
  created() {
    this.getCustomer();
  },
  methods: {
    getCustomer() {
      this.$http.get("customers?list=1").then((response) => {
        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          this.form.optionsCustomer.push($data);
        }
      });
    },
    validation() {
      if (this.form.selectedCustomer === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Customer field is required",
            variant: "warning",
          },
        });
      } else if (this.form.startDate === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "Start Date field is required",
            variant: "warning",
          },
        });
      } else if (this.form.endDate === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "End Date field is required",
            variant: "warning",
          },
        });
      } else {
        this.addContract();
      }
    },
    addContract() {
      var newPrice = this.form.price;

      if (newPrice != null) {
        newPrice = newPrice.replace(/\,/g, "");
      }

      this.$http
        .post("sales/contract/add", {
          customer_id: this.form.selectedCustomer.value,
          start_date: this.form.startDate,
          end_date: this.form.endDate,
          driver: this.form.selectedType.value,
          period: this.form.selectedPeriod.value,
          price: newPrice,
          delivery_address: this.form.deliveryAddress,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success add Contract",
              variant: "success",
            },
          });
          location.href = "/contracts";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>