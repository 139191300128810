var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"8"}},[_c('b-card',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Pelanggan","label-for":"customer"}},[_c('v-select',{attrs:{"id":"customer","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.form.optionsCustomer},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.form.selectedCustomer}},'input',attributes,false),events))]}}]),model:{value:(_vm.form.selectedCustomer),callback:function ($$v) {_vm.$set(_vm.form, "selectedCustomer", $$v)},expression:"form.selectedCustomer"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Jenis Sewa","label-for":"type"}},[_c('v-select',{attrs:{"id":"type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.form.optionsType},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.form.selectedType}},'input',attributes,false),events))]}}]),model:{value:(_vm.form.selectedType),callback:function ($$v) {_vm.$set(_vm.form, "selectedType", $$v)},expression:"form.selectedType"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Periode Sewa","label-for":"period"}},[_c('v-select',{attrs:{"id":"period","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.form.optionsPeriod},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.form.selectedPeriod}},'input',attributes,false),events))]}}]),model:{value:(_vm.form.selectedPeriod),callback:function ($$v) {_vm.$set(_vm.form, "selectedPeriod", $$v)},expression:"form.selectedPeriod"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Mulai","label-for":"start-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  defaultDate: 'today',
                  dateFormat: 'd-m-Y',
                  minDate: 'today',
                }},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Berakhir","label-for":"end-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  dateFormat: 'd-m-Y',
                  minDate: _vm.form.startDate,
                }},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Nilai Kontrak","label-for":"price"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"price","placeholder":"Nilai Kontrak","raw":false,"options":_vm.options.number,"required":""},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Alamat Pengiriman","label-for":"delivery-address"}},[_c('b-form-textarea',{attrs:{"id":"delivery-address","placeholder":"Alamat Pengiriman"},model:{value:(_vm.form.deliveryAddress),callback:function ($$v) {_vm.$set(_vm.form, "deliveryAddress", $$v)},expression:"form.deliveryAddress"}})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mt-2",attrs:{"md":"6"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.validation()}}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }